<template>
	<v-container fluid tag="section">
		<v-form ref="form" v-model="valid" lazy-validation autocomplete="new-password">
			<ti-card :icon="$icons.testimonials">
				<template #title><span>{{ isEdit ? 'Edit' : 'New' }}</span> Testimonial</template>
				<template #title-actions-shown>
					<v-btn color="success" elevation="2" fab small class="mr-4" @click="save">
						<v-icon>{{ $icons.save }}</v-icon>
					</v-btn>
				</template>
				<template #title-actions-hidden>
					<ti-confirm @confirm="destroy" message="Are you sure you?" button-text="Delete">
						<v-btn color="error" elevation="2" fab small class="mr-4">
							<v-icon>{{ $icons.delete }}</v-icon>
						</v-btn>
					</ti-confirm>
				</template>

				<ti-form-layout>
					<template>
						<v-col cols="12">
							<v-textarea v-model="testimonial.content" :rules="validations.content" label="Content"></v-textarea>
						</v-col>
						<v-col cols="12" lg="6">
							<v-text-field v-model="testimonial.author" label="Author"></v-text-field>
						</v-col>
						<v-col cols="12" lg="6">
							<v-text-field v-model="testimonial.author_title" label="Title"></v-text-field>
						</v-col>
						<v-col cols="12" lg="6">
							<v-text-field v-model="testimonial.author_company" label="Company"></v-text-field>
						</v-col>
					</template>
				</ti-form-layout>
			</ti-card>
		</v-form>
	</v-container>
</template>
<script>

import validationRules from "@/util/validation";
import _forEach from 'lodash/forEach'
import {call, sync} from "vuex-pathify";
import Models from "../../models";
import vue from "vue";

export default {

	name: "TestimonialsEdit",
	props: ['id'],
	computed: {
		isEdit() {
			return this.$route.meta?.edit
		},
		testimonial: sync('testimonials/testimonial')
	},
	data: () => ({
		search: '',
		valid: false,
		validations: {
			content: validationRules('Content', ['required', 'minLength:2']),
		}
	}),
	beforeMount() {
		this.init()
	},
	methods: {
		init() {
			if (this.isEdit) {
				this.getTestimonial(this.id)
			} else {
				this.testimonial = Models.Testimonial()
			}
		},
		getTestimonial: call('testimonials/getTestimonial'),
		save() {
			if (this.$refs.form.validate()) {
				if (this.isEdit) {
					this.$api.testimonials.update(this.id, this.testimonial)
						.then(response => {
							this.$toast.add('Testimonial updated successfully', 'success')
							this.$route.meta.disableWarn = true;
							this.$router.push({name: 'Testimonials'})
						})
						.catch(error => {
							console.log(error.response);
							this.$toast.add(error.response.statusText, 'error')
						})
				} else {
					this.$api.testimonials.create(this.testimonial)
						.then(response => {
							this.$toast.add('Testimonial created successfully', 'success')
							this.$route.meta.disableWarn = true;
							this.$router.push({name: 'Testimonials'})
						})
						.catch(error => {
							console.log(error.response);
							this.$toast.add(error.response.statusText, 'error')
						})
				}

			} else {
				this.$toast.add('Please fix validation errors', 'error')
			}
		},
		editResource(resource) {
			let plural = resource.type === 'CaseStudy' ? 'Case_studies' : `${resource.type}s`;
			this.$router.push({name: `${plural}Edit`, params: {id: resource.id}})
		},
		destroy() {
			this.$api.testimonials.delete(this.id, this.testimonial)
				.then(response => {
					this.$toast.add('Testimonial deleted successfully', 'success')
					this.$route.meta.disableWarn = true;
					this.$router.push({name: 'Testimonials'})
				})
				.catch(error => {
					console.log(error.response);
					this.$toast.add(error.response.statusText, 'error')
				})
		},
	}
}
</script>